import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'
import { Container, Row, Col } from 'react-grid-system'
import { Helmet } from 'react-helmet'

import SEO from 'src/components/seo'
import styled from 'styled-components'
import ProjectOverview from 'src/components//projects/project_overview'
import Layout from 'src/components/layout/layout'

const getProjects = data => {
  // console.log(data)
  const { nodes } = data.allMarkdownRemark
  return nodes
}
const getImages = data => {
  const { nodes: images } = data.allFile
  return images
}

export const data = graphql`
  query Projects {
    allMarkdownRemark {
      nodes {
        frontmatter {
          projectName
          projectName2
          path
          order
          projectLocation
          projectLocation2
          cover_credit
          cover_square {
            childImageSharp {
              fixed(width: 400) {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                originalName
              }
              fluid(jpegQuality: 90, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
const FadeWrapper = styled.div`
  height: calc(100% - 2rem);
  // padding-top: 2rem;
  @media (min-width: 1024) {
    padding-top: 0;
    height: calc(100% - 4rem);
  }
`
const OverviewWrap = styled.div`
  height: calc(100% - 2rem);
  padding-top: 2rem;
  @media (min-width: 1024) {
    padding-top: 0;
    height: calc(100% - 4rem);
  }
`
const StyledFade = styled(Fade)``

const StyledCol = styled.div`
order: ${props => props.order};
box-sizing: border-box;
    min-height: 1px;
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0%;
    right: auto;
    left: auto;
}
@media (min-width: 778px){  
  box-sizing: border-box;
  min-height: 1px;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  width: 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-left: 0%;
  right: auto;
  left: auto;
  }
`

const ProjectsPage = ({ data }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])
  const nodes = data.allMarkdownRemark.nodes
  return !loaded ? (
    <Layout />
  ) : (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Martin Jennings - Sculpture</title>
        <meta
          name="description"
          content="John Betjeman at St Pancras Station, Philip Larkin in Hull, Charles Dickens in Portsmouth and George Orwell outside BBC Broadcasting House.
          Celebrated figures from the medical profession have also featured prominently, with John Radcliffe in Oxford, Archibald McIndoe in East Grinstead and Mary Seacole outside St Thomas’ Hospital in London. His sculpture of prime minister Stanley Baldwin stands in Bewdley, Worcestershire, and a seated figure of actor Ronnie Barker in Aylesbury."
        />
      </Helmet>
      <Container>
        <Row>
          {nodes
            ? nodes.map(project => {
                let {
                  projectName,
                  projectName2,
                  path,
                  projectLocation,
                  projectLocation2,
                  cover_square,
                  order,
                  cover_credit,
                } = project.frontmatter
                return (
                  <StyledCol order={order}>
                    <FadeWrapper>
                      <OverviewWrap clear>
                        <ProjectOverview
                          projectName={projectName}
                          projectName2={projectName2}
                          url={path}
                          projectLocation={projectLocation}
                          projectLocation2={projectLocation2}
                          // projectType={projectType}
                          // shortDescription={shortDescription}
                          cover={
                            cover_square &&
                            cover_square.childImageSharp &&
                            cover_square.childImageSharp.fluid
                              ? cover_square.childImageSharp.fluid
                              : null
                          }
                        />
                      </OverviewWrap>
                    </FadeWrapper>
                  </StyledCol>
                )
              })
            : null}
        </Row>
      </Container>
    </Layout>
  )
}

export default ProjectsPage
